<template>
    <div>
        <ImageTextWide :info="welcome"/>
        <h1>Aktuelle Informationen</h1>

        <div style="margin-bottom: 64px;">
            <InfoContainer v-for="info in infos" :info="info" v-bind:key="info.title"/> 
        </div>
        
        <h1 class="bilder">Bildergalerie</h1>
        <ImageSlider :images="images"/>
    </div>
</template>

<script>
import InfoContainer from '../components/InfoContainer.vue'
import ImageTextWide from '../components/ImageTextWide.vue'
import ImageSlider from '../components/ImageSlider.vue'

export default {
  name: 'Homepage',
  data() {
    return {
        welcome: {
            title: "Herzlich Willkommen!",
            description: `<p>Wir heißen Sie herzlich Willkommen auf der Homepage unserer Zuchtstätte Eurasier vom Gonsbachtal.</p>
            <p>Schauen Sie sich um, entdecken und verlieben Sie sich in die wunderbare Hunderasse Eurasier - wie auch wir es getan 
            haben und lernen Sie uns und unsere Zuchtstätte kennen.</p>
            <p>Aktuelle Informationen und Fotos unserer Hunde und Zucht finden Sie auch immer auf unserer <a href="https://www.facebook.com/EurasierVomGonsbachtal/">
            Facebook Seite</a> und für noch schnellere Updates abonnieren sie doch unseren <a href=" https://whatsapp.com/channel/0029Vb4HCLe1SWsxuoKldW2g">
            Whatsappkanal</a>.</p>`,
            img: require('../assets/img/Khali_Platz.jpg'),
            img_description: 'Khaleesi von den Wolfswiesen - Unsere Zuchthündin',
            contact_link: true
        },
        infos: [
            {
                title: "D-Wurf vom Gonsbachtal",
                date: "Stand: 03.04.2025",
                description: `Anfang Mai erwarten wir unseren D-Wurf von unserer Khaleesi von den Wolfswiesen und Amon vom Weißtal. Wir freuen uns sehr und wünschen
                Khaleesi eine gute Tragezeit. <br> <br>
                Interessierte können sich gerne auf die Warteliste unseres Zuchtvereins(<a href="https://www.der-eurasier.de/wurfmeldungen/">Welpenvermittlung</a>) setzen lassen 
                oder auch gerne direkt mit <a href="https://eurasiervomgonsbachtal.de/kontakt">uns</a> Kontakt aufnehmen. Bei einem ungezwungenen
                persönlichen Treffen oder einem Spaziergang können Sie uns und unsere Zuchtstätte näher kennen lernen.`
            },
        ],
        images: [
            {
                title: "Mora, Khaleesi und Arya zu Besuch bei Akito vom Gonsbachtal",
                img: require("../assets/img/Homepage/Gruppenfoto_Akito.jpg")
            },
            {
                title: "Fellpflege muss sein - Arya, Finja und Amon vom Gonsbachtal",
                img: require("../assets/img/Homepage/Welpen2.jpg")
            },
            {
                title: "Wir sind übrigens echte Meenzer - Bahghira vom Gonsbachtal an Fasnacht",
                img: require("../assets/img/Homepage/Fasnacht.jpg")
            },
            {
                title: "Widersehensfreude: Erstmal Spielen - Arya und Akito vom Gonsbachtal",
                img: require("../assets/img/Homepage/Akito_Arya.jpg")
            },
            {
                title: "Zeitsprung - aber gespielt wieder immer noch zusammen",
                img: require("../assets/img/Homepage/Spielen_groß.jpg")
            },
            {
                title: "Unser B-Wurf - einmal still halten fürs Foto bitte",
                img: require("../assets/img/Homepage/B-Wurf.jpg")
            },
            {
                title: "Arya vom Gonsbachtal beim Fotoshoot",
                img: require("../assets/img/Homepage/Arya_Model.jpg")
            },
            {
                title: "Khaleesi von den Wolfswiesen natürlich auch",
                img: require("../assets/img/Homepage/Khaleesi_model.jpg")
            },
            {
                title: "Auch im Urlaub mit dabei - Khaleesi von den Wolfswiesen",
                img: require("../assets/img/Homepage/Khali1.jpg")
            },
            {
                title: "Halbbrüder - Aktio und Baghira vom Gonsbachtal",
                img: require("../assets/img/Homepage/Familie.jpg")
            },
            {
                title: "Khaleesi von den Wolfswiesen - als Welpe",
                img: require("../assets/img/Homepage/Khali2.jpg")
            },
            {
                title: "Akito vom Gonsbachtal",
                img: require("../assets/img/Homepage/Akito_groß.jpg")
            },
            {
                title: "B-Winry vom Gonsbachtal",
                img: require("../assets/img/Homepage/Winry.jpg")
            },
            {
                title: "Als Welpe wird ganz viel gekuschelt",
                img: require("../assets/img/Homepage/Kuscheln.jpg")
            },
            {
                title: "Und auch mit Halbschwester Arya gespielt",
                img: require("../assets/img/Homepage/Spielen.jpg")
            },
            {
                title: "Nie vergessen - Camora von der Mainschleife",
                img: require("../assets/img/Homepage/Mora_Model.jpg")
            },
        ]
    }
  },
  components: {
    InfoContainer,
    ImageTextWide,
    ImageSlider,
}
}
</script>

<style scoped>
:root {
  --backgroundColor: #dcdece;
  --mainText: #333D29;
  --mainTextLight: #70875A;
  --linkText: #936639;
  --focusedText: #582F0E;
  --higlightedText: #04A0A2;
}

.swiper {
    margin-bottom: 0px;
}

h1 {
    color: var(--higlightedText);
    margin-bottom: 8px;
}

h1.bilder {
    color: var(--mainText);
}

</style>